<template>
    <div>
        <edit-models
            v-if="edit && !isViewed"
            :item="item"
            @reset="getData"
            @cancel="edit = false"
            @onSave="onSuccessOperation"
        />
        <div v-if="!edit && !isViewed">
            <breadcrumb
                name="Car Models"
                title="All Car Models"
                :subtitle="add ? 'Create' : edit ? 'Edit' : 'List'"
                @onTitleClicked="closeAddAndEdit()"
            />

            <Create
                v-if="add"
                @onSave="onSuccessOperation"
                @cancel="add = false"
            />

            <div
                v-if="!add && !edit"
                id="data-list-thumb-view"
                class="data-list-container"
            >
                <vs-table
                    ref="table"
                    :sst="true"
                    @search="handleSearch"
                    @sort="handleSort"
                    search
                    :data="items"
                >
                    <div
                        slot="header"
                        class="
                            flex flex-wrap-reverse
                            items-center
                            flex-grow
                            justify-between
                        "
                    >
                        <div
                            class="
                                flex flex-wrap-reverse
                                items-center
                                data-list-btn-container
                            "
                        >
                            <add-new-button @click="add = true" />
                        </div>

                        <!-- ITEMS PER PAGE -->
                        <table-header
                            :current-page="currentPage"
                            :items-per-page="itemsPerPage"
                            :length="items.length"
                            :queried-items="queriedItems"
                            @onChangeItemsPerPage="itemsPerPage = $event"
                        />
                    </div>

                    <template slot="thead">
                        <vs-th>Image</vs-th>
                        <vs-th>Brand</vs-th>
                        <vs-th>Car Type</vs-th>
                        <vs-th sort-key="name_en">English Name</vs-th>
                        <vs-th sort-key="mobile_active">Status</vs-th>
                        <vs-th>Action</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <tbody>
                            <vs-tr
                                :data="tr"
                                :key="indextr"
                                v-for="(tr, indextr) in data"
                            >
                                <image-row :data="tr.image" />
                                <string-row :data="tr.brand.name_en" />
                                <string-row :data="tr.car_type.name_en" />
                                <string-row :data="tr.name_en" />
                                <status-row :data="tr.mobile_active" />
                                <actions-row
                                    show
                                    @onShowClicked="viewData(tr)"
                                    @onEditClicked="editData(tr)"
                                    @onDeleteClicked="deleteData(tr.id)"
                                />
                            </vs-tr>
                        </tbody>
                    </template>
                </vs-table>
            </div>
            <vs-pagination
                v-if="!add && !edit"
                :total="totalItems"
                v-model="page"
            />
        </div>
    </div>
</template>

<script>
import breadcrumb from "../../../../components/general/breadcrumb";
import TableHeader from "../../../../components/table/table-header";
import StringRow from "../../../../components/table/rows/string-row";
import ImageRow from "../../../../components/table/rows/image-row";
import StatusRow from "../../../../components/table/rows/status-row";
import ActionsRow from "../../../../components/table/rows/actions-row";
import Create from "./Create";
import AddNewButton from "../../../../components/form/addnew-button";
import EditModels from "./edit-models";

export default {
    components: {
        EditModels,
        AddNewButton,
        Create,
        ActionsRow,
        StatusRow,
        ImageRow,
        StringRow,
        TableHeader,
        breadcrumb,
    },
    data() {
        return {
            isViewed: false,
            add: false,
            edit: false,
            items: [],
            item: {},
            itemsPerPage: 20,
            isMounted: false,
            page: 1,
            totalItems: 0,
            query: "",
            sortKey: null,
            sortActive: null,
        };
    },
    computed: {
        currentPage() {
            if (this.isMounted) {
                return this.$refs.table.currentx;
            }
            return 0;
        },
        queriedItems() {
            return this.$refs.table
                ? this.$refs.table.queriedResults.length
                : this.items.length;
        },
    },
    watch: {
        page() {
            this.getData();
        },
        query() {
            this.getData();
        },
        sortKey() {
            this.getData();
        },
        sortActive() {
            this.getData();
        },
        itemsPerPage() {
            this.getData();
        },
    },
    methods: {
        viewData(data) {
            //this.item = data;
            //this.isViewed = true;
            this.$router.push("/models/" + data.id);
        },
        closeAddAndEdit() {
            this.add = false;
            this.edit = false;
        },
        onSuccessOperation() {
            this.closeAddAndEdit();
            this.getData();
        },
        deleteData(id) {
            this.$vs.loading();
            this.$httpCars
                .delete(`/models/${id}`)
                .then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Successful operation",
                        text: "Model has beed deleted successfully",
                        color: "success",
                    });
                    this.getData();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error 🙁",
                        text: "Couldn't delete data on the server",
                        color: "danger",
                    });
                });
        },
        editData(data) {
            // this.toggleDataSidebar(true)
            this.item = data;
            this.edit = true;
        },
        handleSearch(query) {
            this.query = query;
        },
        handleSort(key, active) {
            this.sortKey = key;
            this.sortActive = active === "asc" ? 1 : 0;
        },
        getData() {
            this.$vs.loading();
            this.add = false;
            this.edit = false;
            this.isViewed = false;
            this.items = [];
            const queryString = `per_page=${this.itemsPerPage}&page=${this.page}&query=${this.query}&field=${this.sortKey}&ordering=${this.sortActive}`;
            this.$httpCars
                .get(`/models?${queryString}`)
                .then((res) => {
                    this.$vs.loading.close();
                    this.items = res.data.data.models;
                    this.totalItems = Math.ceil(
                        res.data.data.total / this.itemsPerPage
                    );
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error 🙁",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
    },
    mounted() {
        this.isMounted = true;
        this.getData();
    },
};
</script>

<style lang="scss">
#data-list-list-view {
    .vs-con-table {
        /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
        @media (max-width: 689px) {
            .vs-table--search {
                margin-left: 0;
                max-width: unset;
                width: 100%;

                .vs-table--search-input {
                    width: 100%;
                }
            }
        }

        @media (max-width: 461px) {
            .items-per-page-handler {
                display: none;
            }
        }

        @media (max-width: 341px) {
            .data-list-btn-container {
                width: 100%;

                .dd-actions,
                .btn-add-new {
                    width: 100%;
                    margin-right: 0 !important;
                }
            }
        }

        .product-name {
            max-width: 23rem;
        }

        .vs-table--header {
            display: flex;
            flex-wrap: wrap;
            margin-left: 1.5rem;
            margin-right: 1.5rem;

            > span {
                display: flex;
                flex-grow: 1;
            }

            .vs-table--search {
                padding-top: 0;

                .vs-table--search-input {
                    padding: 0.9rem 2.5rem;
                    font-size: 1rem;

                    & + i {
                        left: 1rem;
                    }

                    &:focus + i {
                        left: 1rem;
                    }
                }
            }
        }

        .vs-table {
            border-collapse: separate;
            border-spacing: 0 1.3rem;
            padding: 0 1rem;

            tr {
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

                td {
                    padding: 20px;

                    &:first-child {
                        border-top-left-radius: 0.5rem;
                        border-bottom-left-radius: 0.5rem;
                    }

                    &:last-child {
                        border-top-right-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
                    }
                }

                td.td-check {
                    padding: 20px !important;
                }
            }
        }

        .vs-table--thead {
            th {
                padding-top: 0;
                padding-bottom: 0;

                .vs-table-text {
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }

            th.td-check {
                padding: 0 15px !important;
            }

            tr {
                background: none;
                box-shadow: none;
            }
        }

        .vs-table--pagination {
            justify-content: center;
        }
    }
}
</style>
