<template>
    <div>
        <breadcrumb
            name="Car Models"
            title="Edit Car Model"
            subtitle="Edit"
            @onTitleClicked="$emit('cancel')"
        />
        <div class="form-content bg-white border-radius-10">
            <h6 class="text-danger pa-1">Fields marked with (*) are mandatory</h6>
            <vs-row
                vs-align="center"
                vs-type="flex"
                vs-justify="space-between"
                vs-w="12"
                class="mt-4"
            >
                <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
                    <!-- English NAME -->
                    <vs-input
                        label="English Name *"
                        v-model="form.name_en"
                        class="mt-5 w-full"
                        name="name_en"
                    />
                    <span
                        class="text-danger text-sm"
                        v-show="errors.has('name_en')"
                    >{{ errors.first('name_en') }}</span>
                </vs-col>

                <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
                    <!-- Arabic NAME -->
                    <vs-input
                        label="Arabic Name *"
                        v-model="form.name_ar"
                        class="mt-5 w-full"
                        name="name_ar"
                    />
                    <span
                        class="text-danger text-sm"
                        v-show="errors.has('name_ar')"
                    >{{ errors.first('name_ar') }}</span>
                </vs-col>

                <vs-col class="pa-4" vs-w="6">
                    <label>Brand *</label>
                    <select v-model="form.brand_id" style="height: 40px">
                        <option value="0" selected disabled>Choose Brand</option>
                        <option
                            v-for="(brand, index) in brands"
                            :key="index"
                            :value="brand.id"
                        >{{brand.name_en}}</option>
                    </select>
                </vs-col>

                <vs-col class="pa-4" vs-w="6">
                    <label>Car Type *</label>
                    <select v-model="form.car_type_id" style="height: 40px">
                        <option value="0" selected disabled>Choose Car Type</option>
                        <option
                            v-for="(carType, index) in carTypes"
                            :key="index"
                            :value="carType.id"
                        >{{carType.name_en}}</option>
                    </select>
                </vs-col>

                <vs-col class="pa-4" vs-w="6">
                    <label>Year *</label>
                    <select v-model="form.year" style="height: 40px">
                        <option value="0" selected disabled>Choose Year</option>
                        <option v-for="(year,index) in years" :key="index" :value="year">{{year}}</option>
                    </select>
                </vs-col>

                <vs-col class="pa-4" vs-w="6">
                    <label>Type *</label>
                    <select v-model="form.car_type" style="height: 40px">
                        <option value="0">Passenger</option>
                        <option value="1">Truck</option>
                    </select>
                </vs-col>

                <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
                    <!--En Title warranty-->
                    <vs-input
                        label="Warranty English Title*"
                        min="0"
                        v-model="form.warranties.en.title"
                        class="mt-5 w-full"
                        name="warranty.en.title"
                    />
                </vs-col>

                <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
                    <!--En Title warranty-->
                    <vs-input
                        label="Warranty Arabic Title*"
                        min="0"
                        v-model="form.warranties.ar.title"
                        class="mt-5 w-full"
                        name="warranty.en.title"
                    />
                </vs-col>

                <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
                    <text-area
                        label="Warranty English Details*"
                        v-model="form.warranties.en.text"
                        rows="12"
                        :name="`warranty.en.text`"
                    />
                </vs-col>

                <vs-col vs-type="flex" vs-justify="center" class="pa-4" vs-align="center" vs-w="6">
                    <text-area
                        label="Warranty Arabic Details*"
                        v-model="form.warranties.ar.text"
                        rows="12"
                        :name="`warranty.ar.text`"
                    />
                </vs-col>

<!--                <vs-col class="pa-4" vs-w="6">-->
<!--                    <label for="category">Attach Gallery*</label>-->
<!--                    <select id="category" v-model="form.gallery_id" style="height: 40px">-->
<!--                        <option value="0" selected disabled>Choose Gallery</option>-->
<!--                        <option-->
<!--                            v-for="(gallery, index) in galleries"-->
<!--                            :value="gallery.id"-->
<!--                            :key="index"-->
<!--                        >{{gallery.name}}</option>-->
<!--                    </select>-->
<!--                </vs-col>-->

                <vs-col
                    vs-type="flex"
                    class="pa-4"
                    vs-align="center"
                    vs-w="12"
                    style="margin-top: 20px"
                >
                    <label class="vs-input--label">Show on Mobile*</label>
                    <vs-switch v-model="form.mobile_active" />
                </vs-col>

                <div style="display: flex; flex-direction: row; justify-content:flex-start">
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="6"
                        flex-direction="row"
                    >
                        <upload-file
                            :file-src="form.image"
                            :limit="1"
                            text="Choose Image*"
                            :show-upload-button="false"
                            @getFile="uploadImage"
                        />
                    </vs-col>

                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-w="6"
                        flex-direction="row"
                    >
                        <upload-file
                            :limit="1"
                            :file-src="form.brochure"
                            accept="application/pdf, image/*"
                            text="Upload Brochure*"
                            :show-upload-button="false"
                            @getFile="uploadBrochure"
                        />
                    </vs-col>
                </div>
            </vs-row>

            <div class="submit-actions">
                <vs-button
                    class="mr-6 save"
                    @click="submitData"
                    icon-pack="feather"
                    icon="icon-save"
                    :disabled="!isFormValid"
                >Save</vs-button>
                <vs-button type="border" @click="$emit('reset')">Reset</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import UploadFile from "../../../../components/uploader/upload-file";
import TextArea from "../../../../components/form/text-area";
import { getYears, isUrl } from "../../../../utils/functions";
import Breadcrumb from "../../../../components/general/breadcrumb";
import Services from "../../../../components/form/model-services/services";

export default {
    name: "edit-models",
    components: { Services, Breadcrumb, TextArea, UploadFile },
    props: {
        item: {
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            galleries: [],
            loading: true,
            form: {
                gallery_id: this.item.gallery_id,
                brand_id: this.item.brand.id,
                car_type_id: this.item.car_type.id,
                carType: this.item.carType,
                name_en: this.item.name_en,
                name_ar: this.item.name_ar,
                image: this.item.image,
                year: this.item.year,
                car_type: this.item.carType,
                brochure: this.item.brochure,
                mobile_active: this.item.mobile_active,
                services: this.item.services,

                warranties: {
                    ar: {
                        title:
                            this.item.warranties == null ||
                            this.item.warranties === "[object Object]"
                                ? ""
                                : this.item.warranties.ar.title,
                        text:
                            this.item.warranties == null ||
                            this.item.warranties === "[object Object]"
                                ? ""
                                : this.item.warranties.ar.text
                    },
                    en: {
                        title:
                            this.item.warranties == null ||
                            this.item.warranties === "[object Object]"
                                ? ""
                                : this.item.warranties.en.title,
                        text:
                            this.item.warranties == null ||
                            this.item.warranties === "[object Object]"
                                ? ""
                                : this.item.warranties.en.text
                    }
                }
            },
            years: [],
            brands: [],
            carTypes: []
        };
    },
    computed: {
        isFormValid() {
            return (
                !this.errors.any() &&
                this.form.brand_id &&
                this.form.car_type_id &&
                this.form.name_en &&
                this.form.name_ar &&
                this.form.year &&
                this.form.image &&
                this.form.warranties.en.title.length > 0 &&
                this.form.warranties.ar.text.length > 0 &&
                this.form.warranties.en.title.length > 0 &&
                this.form.warranties.ar.text.length > 0
            );
        }
    },
    methods: {
      uploadImage(event) {

        this.$vs.loading();

        let formData = new FormData();
        formData.append("image", event);
        formData.append("category", "config");

        this.$httpAdmin
          .post("config/upload-media", formData)
          .then(r => {
            this.$vs.loading.close();
            this.form.image = r.data.data;
            console.log('Image Link: ', this.form.image);
            this.$vs.notify({
              title: "Success",
              text: "Image is uploaded successfully!",
              color: "success"
            });
          })
          .catch((e) => {
            this.$vs.loading.close();
            console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Couldn't upload image!",
              color: "danger"
            });
          });
      },
      uploadBrochure(event) {
        this.$vs.loading();

        let formData = new FormData();
        formData.append("image", event);
        formData.append("category", "config");

        this.$httpAdmin
          .post("config/upload-media", formData)
          .then(r => {
            this.$vs.loading.close();
            this.form.brochure = r.data.data;
            console.log('Brochure Link: ', this.form.brochure);
            this.$vs.notify({
              title: "Success",
              text: "Brochure is uploaded successfully!",
              color: "success"
            });
          })
          .catch((e) => {
            this.$vs.loading.close();
            console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Couldn't upload brochure!",
              color: "danger"
            });
          });
      },
      getFolders() {
        this.$httpCars
          .get("gallery-folders")
          .then(r => {
            this.$vs.loading.close();
            let total = r.data.data.total;
            console.log("total", total);
            this.$httpCars
              .get(`gallery-folders?per_page=${total}`)
              .then(r => {
                this.$vs.loading.close();
                this.galleries = r.data.data.data;
                console.log('Galleries: ', this.galleries);
              })
              .catch(() => {
                this.$vs.loading.close();
              });
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      },
        addImage(e) {
            this.images.push(e);
        },
        getAllBrands() {
            this.$vs.loading();
            this.$httpCars
                .get("/brands?ber_page=1000")
                .then(res => {
                    this.brands = res.data.data.brands;
                    this.$vs.loading.close();
                    this.getAllCarTypes();
                })
                .catch(() => {
                    //console.log({ e });
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error 🙁",
                        text: "Couldn't fetch brands from the server",
                        color: "danger"
                    });
                });
        },
        getAllCarTypes() {
            this.$vs.loading();
            this.$httpCars
                .get("/car-types?ber_page=1000")
                .then(res => {
                    this.carTypes = res.data.data.car_types;
                    this.$vs.loading.close();
                    this.getFolders();
                })
                .catch(() => {
                    //console.log({ e });
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error 🙁",
                        text: "Couldn't fetch car types from the server",
                        color: "danger"
                    });
                });
        },

        submitData() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.$vs.loading();
                    const obj = this.form;

                    const formData = new FormData();

                    formData.append("name_en", obj.name_en);
                    formData.append("name_ar", obj.name_ar);
                    formData.append("brand_id", obj.brand_id);
                    formData.append("car_type_id", obj.car_type_id);
                    formData.append("year", obj.year);
                    if (obj.gallery_id) {
                        formData.append("model_gallery_id", obj.gallery_id);
                    }
                    formData.append("car_type", obj.car_type);
                    if (!isUrl(obj.image)) {
                        formData.append("image", obj.image);
                    }
                    if (!isUrl(obj.brochure)) {
                        formData.append("brochure", obj.brochure);
                    }
                    formData.append("mobile_active", Number(obj.mobile_active));
                    formData.append("services", obj.services);
                    formData.append(
                        "warranties[ar][title]",
                        obj.warranties.ar.title
                    );
                    formData.append(
                        "warranties[en][title]",
                        obj.warranties.en.title
                    );
                    formData.append(
                        "warranties[ar][text]",
                        obj.warranties.ar.text
                    );
                    formData.append(
                        "warranties[en][text]",
                        obj.warranties.en.text
                    );

                    this.$httpCars
                        .post(`/models/${this.item.id}`, formData)
                        .then(() => {
                            this.$vs.loading.close();
                            this.$emit("onSave");
                            this.$vs.notify({
                                title: "Successful operation",
                                text: "Model has been updated successfully",
                                color: "success"
                            });
                            this.$vs.loading.close();
                        })
                        .catch(() => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: "Something Error 🙁",
                                text: "Couldn't add model to the server",
                                color: "danger"
                            });
                            //console.log({ error });
                        });
                }
                this.$vs.loading.close();
            });
        }
    },
    created() {},
    mounted() {
        console.log({ item: this.item });
        console.log({ services: this.item.services });

        this.years = getYears();
        this.getAllBrands();
    }
};
</script>

<style scoped lang="scss">
label {
    font-size: 1rem;
    padding: 5px;
}

select {
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #00000030;
}
</style>
